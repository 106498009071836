import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import st from './analytics-item.module.scss';
import BaseTextArea from "../ui/base-text-area/base-text-area";
import ActionBar from "../ui/action-bar/action-bar";
import {AppAnalyticsItem} from "../../model/types/analytics";
import {ActionButtonType} from "../../model/enums/action-button-type";
import {generalTransitionTime, sizeStep} from "../../util/systemVariables";
import RightArrow from "../ui/arrows/right-arrow";
import TopRightArrow from "../ui/arrows/top-right-arrow";
import okGreenIcon from '../../images/icons/ok-green-icon.png';
import {analyticsWasStart, imagesAlt} from "../../util/textVariables";
import {getParent, isLastOfRight} from "../../util/action-functions";
import {useAppSelector} from "../../hooks/reduxHooks";

const AnalyticsItem: React.FC<{ item: AppAnalyticsItem, position: number, updateItem: Function, actionButtonHandler: Function }> = ({
                                                                                                                                        item,
                                                                                                                                        position,
                                                                                                                                        updateItem,
                                                                                                                                        actionButtonHandler
                                                                                                                                    }) => {
    const analytics = useAppSelector(state => state.analytics);

    const leftSpaceRef = useRef<HTMLDivElement>(null);
    const arrowContainerRef = useRef<HTMLDivElement>(null);

    const [text, setText] = useState(item.text);

    useEffect(() => {
        if (item.parentId) {
            if (item.isRoot) {
                // @ts-ignore
                const parent: AppAnalyticsItem = getParent(analytics, item.parentId);
                const columnDifferent = item.column - parent.column - 1;
                (arrowContainerRef as MutableRefObject<HTMLDivElement>).current.style.width = (columnDifferent * 30 * sizeStep) + ((columnDifferent + 1) * 10 * sizeStep) + 'px';
                if (item.column > 0 && position === 0) {
                    if (parent.column === 0) {
                        (leftSpaceRef as MutableRefObject<HTMLDivElement>).current.style.width = (sizeStep * 30) + 'px';
                    } else {
                        (leftSpaceRef as MutableRefObject<HTMLDivElement>).current.style.width = (sizeStep * 30 * (parent.column + 1)) + (parent.column * sizeStep * 10) + 'px';
                    }
                }
            } else {
                (arrowContainerRef as MutableRefObject<HTMLDivElement>).current.style.width = (sizeStep * 10) + 'px';
                if (item.column > 0 && position === 0) {
                    (leftSpaceRef as MutableRefObject<HTMLDivElement>).current.style.width = (sizeStep * 30 * item.column) + ((item.column - 1) * sizeStep * 10) + 'px';
                }
            }
            const arrowTimeout = setTimeout(() => {
                (arrowContainerRef as MutableRefObject<HTMLDivElement>).current.className = [st.arrowContainer, st.active].join(' ');
            }, generalTransitionTime)
            return () => clearTimeout(arrowTimeout);
        }
    }, [item])

    useEffect(() => {
        updateItem({...item, text: text})
    }, [text])

    const onActionButtonClick = (type: ActionButtonType) => {
        actionButtonHandler(type, item)
    }

    const getArrow = () => {
        if (item.parentId) {
            // @ts-ignore
            const parent: AppAnalyticsItem = getParent(analytics, item.parentId);
            if (item.row > 0 && parent.row < item.row) {
                return <TopRightArrow item={item}/>
            } else {
                return <RightArrow/>
            }
        } else {
            return <></>
        }
    }

    const getDateTimeString = () => {
        const dateTime = new Date(item.id);
        const day = String(dateTime.getDate()).length > 1 ? dateTime.getDate() : '0' + dateTime.getDate();
        const month = String(dateTime.getMonth()).length > 1 ? dateTime.getMonth() : '0' + dateTime.getMonth();
        const hour = String(dateTime.getHours()).length > 1 ? dateTime.getHours() : '0' + dateTime.getHours();
        const minutes = String(dateTime.getMinutes()).length > 1 ? dateTime.getMinutes() : '0' + dateTime.getMinutes();
        return `${day}.${month}.${dateTime.getFullYear()}   ${hour}:${minutes}`;
    }

    const getRootIconClass = () => {
        if (item.isRoot) {
            if (item.isActive && !item.parentIsBlocked) {
                return [st.rootIcon, st.active].join(' ')
            } else {
                return [st.rootIcon, st.block].join(' ')
            }
        } else {
            return st.rootIcon;
        }
    }


    return (
        <div className={st.main}>
            <div ref={leftSpaceRef} className={st.leftSpace}/>
            <div ref={arrowContainerRef} className={st.arrowContainer}>
                {getArrow()}
            </div>
            <div className={isLastOfRight(analytics, item) ? [st.card, st.lastRight].join(' ') : st.card}>
                <BaseTextArea text={text} setText={setText} actions={true} isBlocked={!item.isActive}/>
                {
                    !item.parentIsBlocked &&
                    <div className={st.actionBarContainer}>
                        <ActionBar item={item} handler={onActionButtonClick}/>
                    </div>
                }
                <img src={okGreenIcon} alt={imagesAlt}
                     className={getRootIconClass()}/>
            </div>
            {
                (item.row === 0 && item.column === 0) &&
                <div className={st.dateBlock}>
                    <span>{analyticsWasStart}</span>
                    <span>{getDateTimeString()}</span>
                </div>
            }
        </div>
    );
};

export default AnalyticsItem;