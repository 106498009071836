import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import st from './analytics-action.module.scss';
import {AppAnalyticsAction} from "../../model/types/analytics";
import {useAppSelector} from "../../hooks/reduxHooks";
import {sizeStep} from "../../util/systemVariables";
import BaseTextArea from "../ui/base-text-area/base-text-area";
import {deathLineTitle, deleteButtonTitle, imagesAlt, responsibleTitle} from "../../util/textVariables";
import deleteIcon from '../../images/icons/basketIcon.png';

const AnalyticsAction: React.FC<{ action: AppAnalyticsAction, updateAction: Function, noQuestions: boolean, actionButtonHandler: Function }> = ({
                                                                                                                                                    action,
                                                                                                                                                    updateAction,
                                                                                                                                                    noQuestions,
                                                                                                                                                    actionButtonHandler
                                                                                                                                                }) => {
    const rootColumnNumber = useAppSelector(state => state.analytics.rootColumnNumber);

    const leftSpaceRef = useRef<HTMLDivElement>(null);

    const [text, setText] = useState(action.text);
    const [responsible, setResponsible] = useState<string>(action.responsible);
    const [date, setDate] = useState(action.date);

    useEffect(() => {
        updateAction({...action, text: text, responsible: responsible, date: date});
    }, [text, responsible, date])

    useEffect(() => {
        if (noQuestions) {
            (leftSpaceRef as MutableRefObject<HTMLDivElement>).current.style.width = ((rootColumnNumber + 1) * 40 * sizeStep) + 'px';
        }
    }, [rootColumnNumber])

    const deleteActionHandler = () => {
        actionButtonHandler(action)
    }

    return (
        <div className={st.main}>
            <div ref={leftSpaceRef} className={st.leftSpace}/>
            <div className={st.textAreaContainer}>
                <BaseTextArea text={text} setText={setText} actions={false} isBlocked={!action.isActive}/>
                <div
                    className={action.isActive ? st.deleteButtonContainer : [st.deleteButtonContainer, st.notActive].join(' ')}
                    title={deleteButtonTitle} onClick={deleteActionHandler}>
                    <img src={deleteIcon} className={st.deleteImage} alt={imagesAlt}/>
                </div>
            </div>
            <div className={st.inputsContainer}>
                <input className={action.isActive ? st.input : [st.input, st.blocked].join(' ')}
                       defaultValue={responsible} placeholder={responsibleTitle} onChange={event => setResponsible(event.target.value)}/>
                <input className={action.isActive ? st.input : [st.input, st.blocked].join(' ')}
                       defaultValue={date} placeholder={deathLineTitle} onChange={event => setDate(event.target.value)}/>
            </div>
        </div>
    );
};

export default AnalyticsAction;