import React from 'react';
import st from './scale-clue.module.scss';
import {scaleClue} from "../../../util/textVariables";

const ScaleClue = () => {
    return (
        <div className={st.main}>
            <div className={st.textSide}>
                {scaleClue}
            </div>
            <div className={st.showExampleContainer}>
                <div className={st.example}/>
            </div>
        </div>
    );
};

export default ScaleClue;