import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import st from './analytics.-desk.module.scss';
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {setScale} from "../../store/slices/analyticsDeskSlice";
import {
    localStorageCurrentAnalyticsName,
    localStorageHistoryName,
    scaleStep,
    sizeStep,
    supportMail
} from "../../util/systemVariables";
import ScaleHandler from "../scale-handler/scale-handler";
import {AppAnalyticsAction, AppAnalyticsItem} from "../../model/types/analytics";
import AnalyticsItem from "../analytics-item/analytics-item";
import {ActionButtonType} from "../../model/enums/action-button-type";
import {updateAnalytics} from "../../store/slices/analyticsSlice";
import {
    addAction,
    addItem,
    addRow,
    blockItemAndChildren,
    deleteAction,
    deleteItem,
    getActions,
    getAnalyticsWithUpdatedAction,
    getAnalyticsWithUpdatedItem,
    isLastInRow, reBlockItemAndChildren,
    reRootItemAndDeleteActions,
} from "../../util/action-functions";
import AnalyticsAction from "../analytics-action/analytics-action";
import Modal from "../ui/modal/modal";
import {
    hideModal,
    showBlockItemModal,
    showDeleteActionModal,
    showDeleteItemModal,
    showReRootItemModal
} from "../../store/slices/modalSlice";
import ControlQuestion from "../ui/control-question/control-question";
import {ControlQuestionAction} from "../../model/enums/control-question-type";
import HorizontalScrollClue from "../ui/horizontal-scroll-clue/horizontal-scroll-clue";
import mailImg from '../../images/icons/mail.png';
import backStepImg from '../../images/icons/right-arrow.png';
import {backStepTitle, exportButtonTitle, imagesAlt, sendMaleTitle} from "../../util/textVariables";
import ScaleClue from "../ui/scale-clue/scale-clue";
import AnalyticsGeneralActionButton from "../ui/analytics-general-action-button/analytics-general-action-button";
import downloadImg from "../../images/icons/download-icon.png";
import {exportAnalyticsToExcel} from "../../util/export";
import {addToHistory, backStep, changeLastItemInHistory, updateHistory} from "../../store/slices/analyticsHistorySlice";

const AnalyticsDesk = () => {
    const mainRef = useRef<HTMLDivElement>(null);
    const deskRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const analytics = useAppSelector(state => state.analytics);
    const pageScale = useAppSelector(state => state.analyticsDesk.scale);
    const modal = useAppSelector(state => state.modal);

    // Listens combination scroll with ctrl, and changes scaling of page.
    useEffect(() => {
        if (analytics.rows.length === 0) {
            // @ts-ignore
            dispatch(updateAnalytics(JSON.parse(localStorage.getItem(localStorageCurrentAnalyticsName))))
            // @ts-ignore
            dispatch(updateHistory(JSON.parse(localStorage.getItem(localStorageHistoryName))));
        }

        (mainRef as MutableRefObject<HTMLDivElement>).current.addEventListener('wheel', (e) => onScaleHandler(e));
        (mainRef as MutableRefObject<HTMLDivElement>).current.addEventListener('mousemove', (e) => horizontalScrollViewHandler(e));
        return () => {
            (mainRef as MutableRefObject<HTMLDivElement>).current.removeEventListener('wheel', (e) => onScaleHandler(e));
            (mainRef as MutableRefObject<HTMLDivElement>).current.removeEventListener('mousemove', (e) => horizontalScrollViewHandler(e));
        }
    }, [])

    // Scale action function.
    const onScaleHandler = (e: WheelEvent) => {
        if (e.ctrlKey) {
            if (e.deltaY > 0) {
                dispatch(setScale(-scaleStep))
            } else {
                dispatch(setScale(scaleStep))
            }
        }
    }

    // Horizontal scroll clue visible state and function.
    const [isHorizontalScrollClueVisible, setIsHorizontalScrollClueVisible] = useState(false);
    const horizontalScrollViewHandler = (e: any) => {
        if ((window.innerHeight - e.clientY) <= 12) {
            setIsHorizontalScrollClueVisible(true);
        } else {
            setIsHorizontalScrollClueVisible(false);
        }
    }

    // Changes desk scale.
    useEffect(() => {
        (deskRef as MutableRefObject<HTMLDivElement>).current.setAttribute('style', `transform: scale(${pageScale})`);
    }, [pageScale])

    // Updates item in analytics object.
    const updateItem = (item: AppAnalyticsItem) => {
        dispatch(updateAnalytics(getAnalyticsWithUpdatedItem(analytics, item)));
    }

    // Updates action in analytics object.
    const updateAction = (action: AppAnalyticsAction) => {
        dispatch(updateAnalytics(getAnalyticsWithUpdatedAction(analytics, action)));
    }

    // Function for action bar.
    const actionButtonHandler = (type: ActionButtonType, item: AppAnalyticsItem) => {
        switch (type) {
            case(ActionButtonType.plus):
                dispatch(updateAnalytics(addAction(analytics, item)));
                setTimeout(() => {
                    (mainRef as MutableRefObject<HTMLDivElement>).current.scrollLeft += 80 * sizeStep;
                }, 500)
                break;
            case (ActionButtonType.question):
                if (isLastInRow(analytics, item)) {
                    dispatch(updateAnalytics(addItem(analytics, item)));
                    setTimeout(() => {
                        (mainRef as MutableRefObject<HTMLDivElement>).current.scrollLeft += 45 * sizeStep;
                    }, 500)
                } else {
                    dispatch(updateAnalytics(addRow(analytics, item)));
                }
                break;
            case (ActionButtonType.ok):
                if (item.isActive) {
                    dispatch(updateAnalytics(getAnalyticsWithUpdatedItem(analytics, {...item, isRoot: true})));
                } else {
                    dispatch(updateAnalytics(reBlockItemAndChildren(analytics, item)));
                }
                break;
            case ActionButtonType.block:
                if (item.isRoot) {
                    if (getActions(analytics, item).length > 0) {
                        dispatch(showReRootItemModal(item));
                    } else {
                        dispatch(changeLastItemInHistory(analytics));
                        dispatch(updateAnalytics(reRootItemAndDeleteActions(analytics, item)));
                    }
                } else {
                    dispatch(showBlockItemModal(item));
                }
                break;
            case ActionButtonType.delete:
                dispatch(showDeleteItemModal(item));
                break;
        }
    }

    // Function for action delete button.
    const onActionDeleteClick = (action: AppAnalyticsAction) => {
        dispatch(showDeleteActionModal(action));
    }

    // Function for control question (in the modal) submit button.
    const controlQuestionHandler = () => {
        if (modal.item) {
            switch (modal.controlQuestionActionType) {
                case ControlQuestionAction.reRoot:
                    dispatch(changeLastItemInHistory(analytics));
                    dispatch(updateAnalytics(reRootItemAndDeleteActions(analytics, modal.item)));
                    break;
                case ControlQuestionAction.blockItem:
                    dispatch(updateAnalytics(blockItemAndChildren(analytics, modal.item)));
                    break;
                case ControlQuestionAction.deleteItem:
                    dispatch(changeLastItemInHistory(analytics));
                    dispatch(updateAnalytics(deleteItem(analytics, modal.item)));
                    break;
            }
        }

        if (modal.action && modal.controlQuestionActionType === ControlQuestionAction.deleteAction) {
            dispatch(changeLastItemInHistory(analytics));
            dispatch(updateAnalytics(deleteAction(analytics, modal.action)));
        }
        dispatch(hideModal());
    }

    // Hides modal window.
    const hideModalIfActive = (status: boolean) => {
        if (!status) {
            dispatch(hideModal());
        }
    }

    const onSendMaleClick = (e: any) => {
        window.location.href = `mailto:${supportMail}`;
        e.preventDefault();
    }

    const onBackStepClick = () => {
        dispatch(backStep());
    }

    return (
        <div className={st.main} ref={mainRef}>
            <div className={st.deskContainer}>
                <div className={st.desk} ref={deskRef}>
                    {
                        analytics.rows.map(row =>
                            <div key={row.index} className={st.row}>
                                {
                                    row.items.map(item =>
                                        <AnalyticsItem key={item.id} item={item}
                                                       updateItem={updateItem}
                                                       position={row.items.indexOf(item)}
                                                       actionButtonHandler={actionButtonHandler}/>)
                                }
                                {
                                    row.actions.map(action =>
                                        <AnalyticsAction key={action.id}
                                                         action={action}
                                                         updateAction={updateAction}
                                                         noQuestions={row.items.length === 0}
                                                         actionButtonHandler={onActionDeleteClick}/>)
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={st.generalActionButtonsContainer}>
                <AnalyticsGeneralActionButton bottom={150} right={0}>
                    <img src={backStepImg} className={st.backStepButton} alt={imagesAlt} title={backStepTitle}
                         onClick={onBackStepClick}/>
                </AnalyticsGeneralActionButton>
                <AnalyticsGeneralActionButton bottom={100} right={0}>
                    <img src={downloadImg} className={st.downloadButton} alt={imagesAlt} title={exportButtonTitle}
                         onClick={() => exportAnalyticsToExcel(analytics)}/>
                </AnalyticsGeneralActionButton>
                <AnalyticsGeneralActionButton bottom={50} right={0}>
                    <img src={mailImg} className={st.mailButton} alt={imagesAlt} title={sendMaleTitle}
                         onClick={e => onSendMaleClick(e)}/>
                </AnalyticsGeneralActionButton>
                <div className={st.scaleHandlerContainer}>
                    <ScaleHandler/>
                    <div className={st.scaleClueContainer}>
                        <ScaleClue/>
                    </div>
                </div>
            </div>
            <div className={st.horizontalScrollClueContainer}>
                <HorizontalScrollClue isActive={isHorizontalScrollClueVisible}/>
            </div>
            <Modal active={modal.active} setActive={hideModalIfActive}>
                <ControlQuestion action={controlQuestionHandler}/>
            </Modal>
        </div>
    );
};

export default AnalyticsDesk;