import React, {MutableRefObject, useEffect, useRef} from 'react';
import {ActionButtonType} from "../../../model/enums/action-button-type";
import st from './action-button.module.scss';
import plusIcon from '../../../images/icons/plus.png';
import questionIcon from '../../../images/icons/question-icon.png';
import okIcon from '../../../images/icons/ok-icon.png';
import deleteIcon from '../../../images/icons/basketIcon.png';
import {imagesAlt} from "../../../util/textVariables";

const ActionButton: React.FC<{ type: ActionButtonType, orderNumber: number, active: boolean, title: string, onClick: Function }> = ({
                                                                                                                                        type,
                                                                                                                                        orderNumber,
                                                                                                                                        active,
                                                                                                                                        title,
                                                                                                                                        onClick
                                                                                                                                    }) => {

    const mainRef = useRef<HTMLDivElement>(null);

    const getImage = () => {
        switch (type) {
            case ActionButtonType.plus:
                return plusIcon;
            case ActionButtonType.question:
                return questionIcon;
            case ActionButtonType.ok:
                return okIcon;
            case ActionButtonType.block:
                return plusIcon;
            case ActionButtonType.delete:
                return deleteIcon;
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (active) {
                (mainRef as MutableRefObject<HTMLDivElement>).current.className = [st.main, st.active].join(' ');
            } else {
                (mainRef as MutableRefObject<HTMLDivElement>).current.className = st.main;
            }
        }, 100 * orderNumber);
        return () => clearTimeout(timeout);
    }, [active])

    return (
        <div className={st.main} ref={mainRef} title={title} onClick={() => onClick(type)}>
            <img src={getImage()} className={type === ActionButtonType.block ? [st.img, st.rotate].join(' ') : st.img}
                 alt={imagesAlt}/>
        </div>
    );
};

export default ActionButton;