import st from './main-page.module.scss';
import NewAnalyticsCreator from "../../components/new-analytics-creator/new-analytics-creator";

const MainPage = () => {
    return (
        <div className={st.main}>
            <div className={st.newAnalyticsCreatorContainer}>
                <NewAnalyticsCreator/>
            </div>
        </div>
    );
};

export default MainPage;