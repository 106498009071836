import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppAnalytics, AppAnalyticsAction, AppAnalyticsItem} from "../../model/types/analytics";
import {localStorageCurrentAnalyticsName} from "../../util/systemVariables";

const initialState: AppAnalytics = {
    rootColumnNumber: NaN,
    rows: []
}

const Analytics = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        createNewAnalytics(state, text: PayloadAction<string>) {
            state.rows = [
                {
                    index: 0,
                    items: [
                        {
                            id: Date.now(),
                            row: 0,
                            childrenRows: [],
                            column: 0,
                            text: text.payload,
                            isRoot: false,
                            isActive: true,
                            parentIsBlocked: false
                        }
                    ],
                    actions: []
                }
            ];
        },
        updateAnalytics(state, analytics: PayloadAction<AppAnalytics>) {
            // Old items and actions for double functions call check.
            const oldAllItems:any = [];
            const oldAllActions:any = [];
            state.rows.forEach(r => {
                r.items.forEach(i => {
                    oldAllItems.push(i);
                })
                r.actions.forEach(a => {
                    oldAllActions.push(a);
                })
            })
            // New items and actions for double functions call check.
            const newAllItems:any = [];
            const newAllActions:any = [];
            analytics.payload.rows.forEach(r => {
                r.items.forEach(i => {
                    newAllItems.push(i);
                })
                r.actions.forEach(a => {
                    newAllActions.push(a);
                })
            })

            // Double functions call check
            if (JSON.stringify(oldAllItems) !== JSON.stringify(newAllItems)
                || JSON.stringify(oldAllActions) !== JSON.stringify(newAllActions)) {
                // Change state
                state.rows = analytics.payload.rows;
                state.rootColumnNumber = analytics.payload.rootColumnNumber;
                state.isNew = analytics.payload.isNew;
            }
            localStorage.setItem(localStorageCurrentAnalyticsName, JSON.stringify(state));
        },
        updateAnalyticsFromHistory(state, analytics: PayloadAction<AppAnalytics>) {
            // Old items and actions for double functions call check.
            const oldAllItems:any = [];
            const oldAllActions:any = [];
            state.rows.forEach(r => {
                r.items.forEach(i => {
                    oldAllItems.push(i);
                })
                r.actions.forEach(a => {
                    oldAllActions.push(a);
                })
            })
            // New items and actions for double functions call check.
            const newAllItems:any = [];
            const newAllActions:any = [];
            analytics.payload.rows.forEach(r => {
                r.items.forEach(i => {
                    newAllItems.push(i);
                })
                r.actions.forEach(a => {
                    newAllActions.push(a);
                })
            })
            // Double functions call check
            if (JSON.stringify(oldAllItems) !== JSON.stringify(newAllItems)
                || JSON.stringify(oldAllActions) !== JSON.stringify(newAllActions)) {
                // Change state
                state.rows = analytics.payload.rows;
                state.rootColumnNumber = analytics.payload.rootColumnNumber;
                state.isNew = false;
            }
            localStorage.setItem(localStorageCurrentAnalyticsName, JSON.stringify(state));
        }
    },
});

export const {createNewAnalytics, updateAnalyticsFromHistory, updateAnalytics} = Analytics.actions;
export default Analytics.reducer;