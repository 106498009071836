import React from 'react';
import st from './scale-handler.module.scss';
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import plusIcon from '../../images/icons/plus.png'
import minusIcon from '../../images/icons/minus.png'
import {imagesAlt, scaleTo100} from "../../util/textVariables";
import {setScale, setTo100Percents} from "../../store/slices/analyticsDeskSlice";
import {scaleStep} from "../../util/systemVariables";


const ScaleHandler = () => {
    const dispatch = useAppDispatch();
    const pageScale = useAppSelector(state => state.analyticsDesk.scale);

    return (
        <div className={st.main}>
            <div className={st.button} onClick={() => dispatch(setScale(- scaleStep))}>
                <img src={minusIcon} className={st.plusImg} alt={imagesAlt}/>
            </div>
            <div className={st.value} title={scaleTo100} onClick={() => dispatch(setTo100Percents())}>
                {`${(pageScale * 100).toFixed(0)} %`}
            </div>
            <div className={st.button} onClick={() => dispatch(setScale(scaleStep))}>
                <img src={plusIcon} className={st.plusImg} alt={imagesAlt}/>
            </div>
        </div>
    );
};

export default ScaleHandler;