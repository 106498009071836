import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import st from './base-text-area.module.scss';

const BaseTextArea:React.FC<{text:string, setText:Function, actions:boolean, placeholder?:string, isBlocked?:boolean}> = ({text, setText, actions, placeholder, isBlocked}) => {
    const textArea = useRef<HTMLDivElement>(null);

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isInputFocused, setIsInputFocused] = useState(false);

    // Puts text to text area if it exists in first render.
    useEffect(() => {
        if (isFirstRender) {
            if (text !== '') (textArea as MutableRefObject<HTMLDivElement>).current.innerHTML = text;
            setIsFirstRender(false);
        }
    }, [isFirstRender])

    // Returns base text area styles by actions flag.
    const getBaseStyles = ():string => {
        return actions ? [st.textarea, st.lessWide].join(' ') : st.textarea;
    }

    // Listening text change and updates text state and some textarea styles (to make text readable with the scroll).
    useEffect(() => {
        const textElement = (textArea as MutableRefObject<HTMLDivElement>).current;
        textElement.addEventListener("focus", () => onFocus(textElement));
        textElement.addEventListener("blur", () => onBlur(textElement));
        textElement.addEventListener("input", () => onTypeText(textElement));
        return () => {
            textElement.removeEventListener("focus", () => onFocus(textElement));
            textElement.removeEventListener("blur", () => onBlur(textElement));
            textElement.removeEventListener("input", () => onTypeText(textElement));
        }
    }, [])

    // Function on type text in main input.
    const onTypeText = (textElement:HTMLDivElement) => {
        setText(textElement.innerText);
        if (textElement.scrollHeight > textElement.offsetHeight) {
            textElement.style.justifyContent = 'start';
        } else {
            textElement.style.justifyContent = 'center';
        }
    }

    // Function on focus main input.
    const onFocus = (textElement:HTMLDivElement) => {
        setIsInputFocused(true);
    }
    // Function on blur main input.
    const onBlur = (textElement:HTMLDivElement) => {
        setIsInputFocused(false);
    }

    // Puts cursor to center when text string is empty.
    useEffect(() => {
        if (text === '') {
            (textArea as MutableRefObject<HTMLDivElement>).current.className = [getBaseStyles(), st.empty].join(' ');
        } else {
            (textArea as MutableRefObject<HTMLDivElement>).current.className = getBaseStyles();
        }
    }, [text])

    return (
        <div className={st.main}>
            <div contentEditable={true} ref={textArea}/>
            {
                (placeholder && text.replaceAll(' ', '').length === 0 && !isInputFocused) &&
                <div className={st.placeholder}>{placeholder}</div>
            }
            <div className={isBlocked ? [st.blockMark, st.active].join(' ') : st.blockMark}/>
        </div>
    );
};

export default BaseTextArea;
