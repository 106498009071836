import React, {MutableRefObject, useEffect, useRef} from 'react';
import st from './arrows.module.scss';
import {AppAnalyticsItem} from "../../../model/types/analytics";
import {sizeStep} from "../../../util/systemVariables";
import {getParent} from "../../../util/action-functions";
import {useAppSelector} from "../../../hooks/reduxHooks";

const TopRightArrow:React.FC<{item:AppAnalyticsItem}> = ({item}) => {
    const analytics = useAppSelector(state => state.analytics);

    const topRightArrowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (item.parentId) {
            // @ts-ignore
            const parent:AppAnalyticsItem = getParent(analytics, item.parentId);
            (topRightArrowRef as MutableRefObject<HTMLDivElement>).current.style.height = (sizeStep * 20) * (item.row - parent.row) + 'px';
        }
    }, [item])

    return (
        <div className={st.main}>
            <div className={st.topRightArrow} ref={topRightArrowRef}>
                <div className={st.arrowContainer}>
                    <div className={st.arrow}/>
                </div>
            </div>
        </div>
    );
};

export default TopRightArrow;