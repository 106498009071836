import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ControlQuestionAction, ControlQuestionType} from "../../model/enums/control-question-type";
import {
    blockControlButtonText,
    blockItemControlQuestion, deleteActionControlQuestion,
    deleteControlButtonText,
    deleteItemControlQuestion, imSureButtonText, reRootItemControlQuestion
} from "../../util/textVariables";
import {AppAnalyticsAction, AppAnalyticsItem} from "../../model/types/analytics";

export type ModalState = {
    active: boolean;
    text: string[];
    buttonText: string;
    controlQuestionType: ControlQuestionType;
    controlQuestionActionType: ControlQuestionAction;
    item?: AppAnalyticsItem;
    action?: AppAnalyticsAction;
}

const initialState: ModalState = {
    active: false,
    text: [],
    buttonText: '',
    controlQuestionType: ControlQuestionType.question,
    controlQuestionActionType: ControlQuestionAction.blockItem
}

const ModalState = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showReRootItemModal(state, item: PayloadAction<AppAnalyticsItem>) {
            state.active = true;
            state.text = reRootItemControlQuestion;
            state.buttonText = imSureButtonText;
            state.controlQuestionType = ControlQuestionType.alarm;
            state.controlQuestionActionType = ControlQuestionAction.reRoot;
            state.item = item.payload;
        },
        showBlockItemModal(state, item: PayloadAction<AppAnalyticsItem>) {
            state.active = true;
            state.text = blockItemControlQuestion;
            state.buttonText = blockControlButtonText;
            state.controlQuestionType = ControlQuestionType.alarm;
            state.controlQuestionActionType = ControlQuestionAction.blockItem;
            state.item = item.payload;
        },
        showDeleteItemModal(state, item: PayloadAction<AppAnalyticsItem>) {
            state.active = true;
            state.text = deleteItemControlQuestion;
            state.buttonText = deleteControlButtonText;
            state.controlQuestionType = ControlQuestionType.alarm;
            state.controlQuestionActionType = ControlQuestionAction.deleteItem;
            state.item = item.payload;
        },
        showDeleteActionModal(state, action: PayloadAction<AppAnalyticsAction>) {
            state.active = true;
            state.text = deleteActionControlQuestion;
            state.buttonText = deleteControlButtonText;
            state.controlQuestionType = ControlQuestionType.question;
            state.controlQuestionActionType = ControlQuestionAction.deleteAction;
            state.action = action.payload;
        },
        hideModal(state) {
            state.active = false;
        }
    },
});

export const {showReRootItemModal, showBlockItemModal, showDeleteItemModal, showDeleteActionModal, hideModal} = ModalState.actions;
export default ModalState.reducer;