import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type AnalyticsDesk = {
    scale: number;
}

const initialState: AnalyticsDesk = {
    scale: 1
}

const AnalyticsDeskState = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setScale(state, scalePosition: PayloadAction<number>) {
            if ((scalePosition.payload < 0 && state.scale > 0.2) || (scalePosition.payload > 0 && state.scale < 2)) {
                state.scale = state.scale + scalePosition.payload;
            }
        },
        setTo100Percents(state) {
            state.scale = 1;
        },
    },
});

export const {setScale, setTo100Percents} = AnalyticsDeskState.actions;
export default AnalyticsDeskState.reducer;