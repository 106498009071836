import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppAnalytics, AppAnalyticsItem} from "../../model/types/analytics";
import {localStorageHistoryName, maxCountOfHistorySteps} from "../../util/systemVariables";

type AnalyticsHistory = {
    history: AppAnalytics[],
    currentIndex: number;
}

const initialState: AnalyticsHistory = {
    history: [],
    currentIndex: -1
}

const AnalyticsHistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        addToHistory(state, analytics: PayloadAction<AppAnalytics>) {
            const currentStepIsLastOfHistory = state.currentIndex === state.history.length - 1;

            if (state.history.length < maxCountOfHistorySteps && currentStepIsLastOfHistory) {
                state.history.push(analytics.payload);
                state.currentIndex++;
            } else if (!currentStepIsLastOfHistory) {
                const newHistory: AppAnalytics[] = [];
                for (let i = 0; i < state.history.length; i++) {
                    if (i <= state.currentIndex) {
                        newHistory.push(state.history[i]);
                    }
                }
                state.history = newHistory;
                state.history.push(analytics.payload);
                state.currentIndex++;
            } else {
                const newHistory: AppAnalytics[] = [];
                for (let i = 1; i < state.history.length; i++) {
                    newHistory.push(state.history[i]);
                }
                newHistory.push(analytics.payload);
                state.history = newHistory;
            }
            localStorage.setItem(localStorageHistoryName, JSON.stringify(state));
        },
        changeLastItemInHistory(state, analytics: PayloadAction<AppAnalytics>) {
            state.history[state.currentIndex] = analytics.payload;
        },
        updateHistory(state, lastHistory: PayloadAction<AnalyticsHistory>) {
            state.history = lastHistory.payload.history;
            state.currentIndex = lastHistory.payload.currentIndex;
        },
        backStep(state) {
            if (state.currentIndex > 0) {
                state.currentIndex--
            }
        }
    },
});

export const {addToHistory, changeLastItemInHistory, updateHistory, backStep} = AnalyticsHistorySlice.actions;
export default AnalyticsHistorySlice.reducer;