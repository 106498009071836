import st from './regular-button.module.scss';

const RegularButton:React.FC <{isActive: boolean, children:string, onClick: Function}> = ({isActive, children, onClick}) => {

    const onButtonClick = () => {
        onClick();
    }

    return (
        <div className={isActive ? [st.main, st.action].join(' ') : st.main} onClick={onButtonClick}>
            {children}
        </div>
    );
};

export default RegularButton;