import React, {useMemo, useState} from 'react';
import st from './new-analytics-creator.module.scss';
import BaseTextArea from "../ui/base-text-area/base-text-area";
import {appTitle, imagesAlt, mainPageTextAreaPlaceholder, startAnalyticsButton} from "../../util/textVariables";
import {useNavigate} from "react-router-dom";
import {analyticsRoute} from "../../util/routes";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {createNewAnalytics} from "../../store/slices/analyticsSlice";
import logoImg from "../../images/logo.png";
import RegularButton from "../ui/regular-button/regular-button";

const NewAnalyticsCreator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // First question to new analytics.
    const [text, setText] = useState('');

    // Analytics start button state.
    const startButtonIsActive = useMemo(() => {
        return text.replaceAll(' ', '').length > 0;
    }, [text])

    // Opens analytics page.
    const startAnalyticsHandler = () => {
        dispatch(createNewAnalytics(text));
        navigate(analyticsRoute)
    }

    return (
        <div className={st.main}>
            <div className={st.contentContainer}>
                <div className={st.logoContainer}>
                    <img src={logoImg} className={st.logo} alt={imagesAlt}/>
                    <span className={st.appTitle} translate={"no"}>{appTitle}</span>
                </div>
                    <BaseTextArea text={text} setText={setText} actions={false} placeholder={mainPageTextAreaPlaceholder}/>
                <RegularButton isActive={startButtonIsActive} onClick={startAnalyticsHandler}>
                    {startAnalyticsButton}
                </RegularButton>
            </div>
        </div>
    );
};

export default NewAnalyticsCreator;