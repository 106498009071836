export enum ControlQuestionType {
    question,
    alarm
}

export enum ControlQuestionAction {
    blockItem,
    deleteItem,
    deleteAction,
    reRoot
}