import React from 'react';
import st from './control-question.module.scss';
import {imagesAlt} from "../../../util/textVariables";
import {ControlQuestionType} from "../../../model/enums/control-question-type";
import alarmIcon from '../../../images/icons/alarm-icon.png';
import questionIcon from '../../../images/icons/alarm-question-icon.png';
import {useAppSelector} from "../../../hooks/reduxHooks";

const ControlQuestion: React.FC<{ action: Function }> = ({ action }) => {
    const modal = useAppSelector(state => state.modal);

    const onClick = () => {
        action();
    }

    const getImage = () => {
        switch (modal.controlQuestionType) {
            case ControlQuestionType.question:
                return questionIcon;
            case ControlQuestionType.alarm:
                return alarmIcon;
        }
    }

    return (
        <div className={st.main}>
            <div className={st.imageContainer}>
                <img src={getImage()} className={st.icon} alt={imagesAlt}/>
            </div>
            <div className={st.textArea}>
                {
                    modal.text.map(t =>
                        <span key={t} className={modal.text.indexOf(t) < modal.text.length - 1 ? st.text : [st.text, st.final].join(' ')}>{t}</span>
                    )
                }
            </div>
            <div className={st.buttonArea}>
                <div className={st.button} onClick={onClick}>
                    {modal.buttonText}
                </div>
            </div>
        </div>
    );
};

export default ControlQuestion;