// header
export const appTitle = 'Why-Why';
export const exportButtonTitle = 'Экспорт с Excel'

// mainPage
export const startAnalyticsButton = 'Начать анализ';
export const mainPageTextAreaPlaceholder = 'Введите первоначальное описание проблемы для начала анализа';

// analyticsDesk
export const analyticsWasStart = 'Анализ начат';
export const scaleTo100 = 'Вернуть масштаб 100%';
export const whyButtonTitle = 'Почему?';
export const whatButtonTitle = 'Что?';
export const rootButtonTitle = 'Сделать корневой причиной';
export const unRootButtonTitle = 'Отменить статус корневой причины';
export const blockButtonTitle = 'Заблокировать';
export const unblockButtonTitle = 'Разблокировать';
export const deleteButtonTitle = 'Удалить';
export const makeAction = 'Добавить действие';
export const responsibleTitle = 'Ответственный';
export const deathLineTitle = 'Срок выполнения';
export const reRootItemControlQuestion = ['Если сделать вопрос не корневым все его действия будут удалены.', 'Вы уверены что хотите лишить вопрос статуса корневой причины?'];
export const blockItemControlQuestion = ['После блокировки элемента все последующие в цепочке элементы будут заблокированы.', 'Вы уверены что хотите заблокировать элемент?'];
export const deleteItemControlQuestion = ['Вместе с элементом будут удалены все последующие в цепочке вопросы и действия.', 'Вы уверены что хотите удалить элемент?'];
export const deleteActionControlQuestion = ['Вы уверены что хотите удалить действие?'];
export const imSureButtonText = 'Уверен';
export const blockControlButtonText = 'Заблокировать';
export const deleteControlButtonText = 'Удалить';
export const horizontalScrollClue = 'shift + колёсико мыши';
export const scaleClue = 'ctrl + колёсико мыши';
export const sendMaleTitle = 'Написать в поддержку';
export const backStepTitle = 'Шаг назад';

// general
export const imagesAlt = 'Не удалось загрузить изображение';