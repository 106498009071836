import {
    AppAnalytics,
    AppAnalyticsAction,
    AppAnalyticsItem,
    AppAnalyticsRow,
    AppElement
} from "../model/types/analytics";

// Updates item in the analytics document.
export const getAnalyticsWithUpdatedItem = (analytics: AppAnalytics, item: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    let mutableItem: AppAnalyticsItem = structuredClone(item);
    // Checks whether the element was the root. Updates item column index if true.
    if (!mutableItem.isRoot) {
        updateItemColumnWhenMadeNotRoot(mutableAnalytics, mutableItem)
    }
    // Updates item in analytics document.
    mutableAnalytics.rows[mutableItem.row].items.forEach(it => {
        if (it.id === mutableItem.id) {
            mutableAnalytics.rows[mutableItem.row].items[mutableAnalytics.rows[mutableItem.row].items.indexOf(it)] = mutableItem;
        }
    });
    // If item is root updates item column.
    if (mutableItem.isRoot) {
        updateItemColumnWhenMadeRoot(mutableAnalytics);
    }

    mutableAnalytics.isNew = true;
    analytics.rows[mutableItem.row].items.forEach(it => {
        if (it.id === mutableItem.id
            && it.row === mutableItem.row
            && it.column === mutableItem.column
            && it.isRoot === mutableItem.isRoot
            && it.isActive === mutableItem.isActive
            && it.text !== mutableItem.text) {
            mutableAnalytics.isNew = false;
        }
    });

    // Returns result.
    return mutableAnalytics;
}

// Updates action in the analytics document.
export const getAnalyticsWithUpdatedAction = (analytics: AppAnalytics, action: AppAnalyticsAction): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    // Updates item in analytics document.
    mutableAnalytics.rows[action.row].actions.forEach(ac => {
        if (ac.id === action.id) {
            mutableAnalytics.rows[action.row].actions[mutableAnalytics.rows[action.row].actions.indexOf(ac)] = action;
        }
    });

    mutableAnalytics.isNew = true;
    analytics.rows[action.row].actions.forEach(ac => {
        if (ac.id === action.id
            && ac.row === action.row
            && ac.parentId === action.parentId
            && (ac.text !== action.text
                || ac.responsible !== action.responsible
                || ac.date !== action.date)) {
            mutableAnalytics.isNew = false;
        }
    });

    // Returns result.
    mutableAnalytics.isNew = false;
    return mutableAnalytics;
}

// Adds new item to next position in the row.
export const addItem = (analytics: AppAnalytics, parent: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    mutableAnalytics.rows[parent.row].items.push({
        id: Date.now(),
        parentId: parent.id,
        childrenRows: [],
        row: parent.row,
        column: parent.column + 1,
        text: '',
        isRoot: false,
        isActive: true,
        parentIsBlocked: false
    })

    updateItemColumnWhenMadeRoot(mutableAnalytics);
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Add new action to analytics document.
export const addAction = (analytics: AppAnalytics, parent: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    const actions = getActions(analytics, parent);
    const newRowIndex = parent.row + actions.length;
    if (newRowIndex === parent.row) {
        mutableAnalytics.rows[parent.row].actions.push({
            id: Date.now(),
            row: newRowIndex,
            text: '',
            parentId: parent.id,
            responsible: '',
            date: '',
            isActive: true
        })
    } else {
        let newRowIsLast = updateRowIndexes(mutableAnalytics, parent, newRowIndex);
        // Adds new row with empty action to analytics document.
        if (newRowIsLast) {
            mutableAnalytics.rows.push({
                index: newRowIndex,
                items: [],
                actions: [
                    {
                        id: Date.now(),
                        row: newRowIndex,
                        text: '',
                        parentId: parent.id,
                        responsible: '',
                        date: '',
                        isActive: true
                    }
                ]
            })
        } else {
            const newRows: AppAnalyticsRow[] = [];
            mutableAnalytics.rows.forEach(r => {
                newRows[r.index] = r;
                newRows[newRowIndex] = {
                    index: newRowIndex,
                    items: [],
                    actions: [
                        {
                            id: Date.now(),
                            row: newRowIndex,
                            text: '',
                            parentId: parent.id,
                            responsible: '',
                            date: '',
                            isActive: true
                        }
                    ]
                }
            })
            mutableAnalytics.rows = newRows;
        }
    }
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Adds new row with empty item to analytics document.
export const addRow = (analytics: AppAnalytics, parent: AppAnalyticsItem): AppAnalytics => {
    const newRowIndex = getNewRowIndex(analytics, parent);
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    let newRowIsLast = updateRowIndexes(mutableAnalytics, parent, newRowIndex);

    // Adds new row with empty item to analytics document.
    if (newRowIsLast) {
        mutableAnalytics.rows.push({
            index: newRowIndex,
            items: [
                {
                    id: Date.now(),
                    parentId: parent.id,
                    childrenRows: [],
                    row: newRowIndex,
                    column: parent.column + 1,
                    text: '',
                    isRoot: false,
                    isActive: true,
                    parentIsBlocked: false
                }
            ],
            actions: []
        })
    } else {
        const newRows: AppAnalyticsRow[] = [];
        mutableAnalytics.rows.forEach(r => {
            newRows[r.index] = r;
            newRows[newRowIndex] = {
                index: newRowIndex,
                items: [
                    {
                        id: Date.now(),
                        parentId: parent.id,
                        childrenRows: [],
                        row: newRowIndex,
                        column: parent.column + 1,
                        text: '',
                        isRoot: false,
                        isActive: true,
                        parentIsBlocked: false
                    }
                ],
                actions: []
            }
        })
        mutableAnalytics.rows = newRows;
    }
    updateItemColumnWhenMadeRoot(mutableAnalytics);
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Makes item not root and deletes all his actions if them exists.
export const reRootItemAndDeleteActions = (analytics: AppAnalytics, item: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    mutableAnalytics.rows[item.row].items.forEach(i => {
        if (i.id === item.id) {
            deleteActions(mutableAnalytics, i);
            i.isRoot = false;
            // @ts-ignore
            const parent = getParent(analytics, i.parentId);
            // @ts-ignore
            if (i.column === parent?.column + 1) {
                mutableAnalytics.rows.forEach(r => {
                    r.items.forEach(i => {
                        if (i.isRoot) {
                            i.column += 1;
                        }
                    })
                })
            } else {
                // @ts-ignore
                i.column = parent?.column + 1;
            }
        }
    })
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Deletes item from analytics document
export const deleteItem = (analytics: AppAnalytics, item: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    let rowWasDeleted: number[] = []; // [first deleted row index, last deleted row index, deleted rows count]

    const nextChildrenRows: number[] = [];
    const deepChildren = getDeepChildren(mutableAnalytics, item);
    deepChildren.forEach(dc => {
        if (!nextChildrenRows.includes(dc.row) && dc.row !== item.row) {
            nextChildrenRows.push(dc.row);
        }
    });

    // Deletes item or row.
    const itemsIndexInRow = analytics.rows[item.row].items.indexOf(item);
    if (itemsIndexInRow === 0) {
        const deletedRowsCount = nextChildrenRows.length > 0 ? nextChildrenRows.length + 1 : 1;
        mutableAnalytics.rows.splice(item.row, deletedRowsCount);
        rowWasDeleted = [item.row, item.row + deletedRowsCount - 1, deletedRowsCount];
    } else {
        mutableAnalytics.rows[item.row].items.splice(itemsIndexInRow, mutableAnalytics.rows[item.row].items.length - itemsIndexInRow)
        mutableAnalytics.rows[item.row].actions = [];
        if (nextChildrenRows.length > 0) {
            mutableAnalytics.rows.splice(getMin(nextChildrenRows), nextChildrenRows.length);
            rowWasDeleted = [getMin(nextChildrenRows), getMax(nextChildrenRows), nextChildrenRows.length];
        }
    }
    actualizeRowIndexes(mutableAnalytics, rowWasDeleted[1], rowWasDeleted[2]);
    updateItemColumnWhenMadeRoot(mutableAnalytics);
    liftUpChildrenItemWhenParentRowIsEmpty(mutableAnalytics);
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Makes item and all his children and their actions not active.
export const blockItemAndChildren = (analytics: AppAnalytics, item: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);

    mutableAnalytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (i.id === item.id) {
                i.isActive = false;
            }
        })
    })

    const deepChildren: AppElement[] = getDeepChildren(mutableAnalytics, item);
    deepChildren.forEach(dc => {
        dc.isActive = false;
        if (Object.keys(dc).includes('parentIsBlocked')) {
            // @ts-ignore
            dc.parentIsBlocked = true;
        }
    })

    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Makes item and all his children and their actions not active.
export const reBlockItemAndChildren = (analytics: AppAnalytics, item: AppAnalyticsItem): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);

    mutableAnalytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (i.id === item.id) {
                i.isActive = true;
            }
        })
    })

    const deepChildren: AppElement[] = getDeepChildren(mutableAnalytics, item);
    deepChildren.forEach(dc => {
        dc.isActive = true;
        if (Object.keys(dc).includes('parentIsBlocked')) {
            // @ts-ignore
            dc.parentIsBlocked = false;
        }
    })

    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Deletes action from analytics document
export const deleteAction = (analytics: AppAnalytics, action: AppAnalyticsAction): AppAnalytics => {
    let mutableAnalytics: AppAnalytics = structuredClone(analytics);
    mutableAnalytics.rows.forEach(r => {
        r.actions.forEach(a => {
            if (a.id === action.id) {
                if (r.items.length > 0) {
                    r.actions = [];
                    // @ts-ignore
                    const parent = getParent(analytics, action.parentId);
                    // @ts-ignore
                    const allActions = getActions(analytics, parent);
                    if (allActions.length > 1) {
                        const nextActionRow = getMin(allActions.map(a => a.row)) + 1;
                        analytics.rows[nextActionRow].actions.forEach(a => {
                            r.actions.push({...a, row: r.index})
                        })
                        mutableAnalytics.rows.splice(nextActionRow, 1);
                        actualizeRowIndexes(mutableAnalytics, nextActionRow, 1);
                    }
                } else {
                    mutableAnalytics.rows.splice(a.row, 1);
                    actualizeRowIndexes(mutableAnalytics, a.row, 1);
                }
            }
        })
    })
    mutableAnalytics.isNew = true;
    return mutableAnalytics;
}

// Returns parent item by id.
export const getParent = (analytics: AppAnalytics, parentId: number): AppAnalyticsItem | null => {
    let result: AppAnalyticsItem;
    analytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (i.id === parentId) {
                result = i;
            }
        })
    })
    // @ts-ignore
    return result;
}

// Checks item is last in the row or not.
export const isLastInRow = (analytics: AppAnalytics, item: AppAnalyticsItem): boolean => {
    let result = false;
    analytics.rows.forEach(r => {
        if (r.index === item.row) {
            r.items.forEach(i => {
                if (i.id === item.id && r.items.indexOf(i) === r.items.length - 1) {
                    result = true;
                }
            })
        }
    })
    return result;
}

// Returns all actions by root item.
export const getActions = (analytics: AppAnalytics, parent: AppAnalyticsItem) => {
    const result: AppAnalyticsAction[] = [];
    analytics.rows.forEach(r => {
        r.actions.forEach(a => {
            if (a.parentId === parent.id) {
                result.push(a);
            }
        })
    })
    return result;
}

export const isLastOfRight = (analytics: AppAnalytics, item: AppAnalyticsItem) => {
    let isActionsAreExist = false;
    analytics.rows.forEach(r => {
        r.actions.forEach(a => {
            isActionsAreExist = true;
        })
    })

    if (isActionsAreExist) {
        return false;
    } else {
        let isRootItemsExist = false;
        let maxColumnNumber = 0;
        analytics.rows.forEach(r => {
            r.items.forEach(i => {
                if (i.isRoot) {
                    isRootItemsExist = true;
                }
                if (i.column > maxColumnNumber) {
                    maxColumnNumber = i.column;
                }
            })
        })

        if (isRootItemsExist) {
            return item.isRoot;
        } else {
            return item.column === maxColumnNumber;
        }
    }
}

// Deletes all actions by parent item.
const deleteActions = (analytics: AppAnalytics, parent: AppAnalyticsItem) => {
    const actions = getActions(analytics, parent);
    const rowsToDelete = actions.map(a => a.row).filter(rowIndex => rowIndex !== parent.row);
    analytics.rows[parent.row].actions = [];
    analytics.rows.splice(getMin(rowsToDelete), rowsToDelete.length);
    actualizeRowIndexes(analytics, getMax(rowsToDelete), rowsToDelete.length)
}

// Actualizes row indexes in analytics document after delete row.
const actualizeRowIndexes = (analytics: AppAnalytics, lastDeletedRowIndex: number, deletedRowsCount: number) => {
    analytics.rows.forEach(r => {
        // in items and rows
        if (r.index > lastDeletedRowIndex) {
            r.index -= deletedRowsCount;
            r.items.forEach(i => {
                i.row -= deletedRowsCount;
            })
            r.actions.forEach(a => {
                a.row -= deletedRowsCount;
            })
        }
        // in children rows attributes
        r.items.forEach(i => {
            if (i.childrenRows.length > 0) {
                i.childrenRows.forEach(cr => {
                    if (cr > lastDeletedRowIndex) {
                        i.childrenRows[i.childrenRows.indexOf(cr)] -= deletedRowsCount;
                    }
                })
            }
        })
    })
}

// Actualize row indexes in analytics document after add new row.
const updateRowIndexes = (analytics: AppAnalytics, parent: AppAnalyticsItem, newRowIndex: number): boolean => {
    let newRowIsLast = true;

    // Changes over indexes in all analytics document (items and childrenRows)
    analytics.rows.forEach(r => {
        if (r.index >= newRowIndex) {
            r.index += 1;
            r.items.forEach(i => {
                const children = getChildren(analytics, i);
                i.row += 1;
                children.forEach(ch => {
                    // @ts-ignore
                    ch.parent = i;
                })
            })
            newRowIsLast = false;
        } else {
            r.items.forEach(i => {
                i.childrenRows.forEach(cr => {
                    if (cr >= newRowIndex) {
                        i.childrenRows[i.childrenRows.indexOf(cr)] += 1;
                    }
                })
            })
        }
    })

    // Adds new row index to parents children rows array.
    analytics.rows[parent.row].items.forEach(item => {
        if (item.id === parent.id) {
            item.childrenRows.push(newRowIndex);
        }
    });

    return newRowIsLast;
}

// Puts next rows items to current row when user deletes this row children.
const liftUpChildrenItemWhenParentRowIsEmpty = (analytics: AppAnalytics) => {
    analytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (!i.isRoot && i.childrenRows.length > 0 && isLastInRow(analytics, i)) {
                const nearestChildrenRow = getMin(i.childrenRows);
                const rowsForLiftUp: number[] = getChildrenChildrenRows(analytics, nearestChildrenRow);

                console.log(rowsForLiftUp);
                // analytics.rows[nearestChildrenRow].items.forEach(childrenItem => {
                //     childrenItem.childrenRows.forEach(ccri => {
                //         rowsForLiftUp.push(ccri);
                //     })
                //     // analytics.rows[i.row].items.push({...childrenItem, row: i.row})
                //     // analytics.rows.splice(nearestChildrenRow, 1);
                //     // actualizeRowIndexes(analytics, nearestChildrenRow, 1);
                // })
            }
        })
    })
}

const getChildrenChildrenRows = (analytics: AppAnalytics, firstChildrenRowIndex: number): number[] => {
    const result: number[] = [firstChildrenRowIndex];
    analytics.rows[firstChildrenRowIndex].items.forEach(childrenRowsItem => {
        if (childrenRowsItem.childrenRows.length > 0) {
            childrenRowsItem.childrenRows.forEach(childrenChildrenRowIndex => {
                getChildrenChildrenRows(analytics, childrenChildrenRowIndex).forEach(childrenChildrenRowResult => {
                    result.push(childrenChildrenRowResult);
                })
            })
        }
    })
    return result;
}

// Update row index of item when not root item made root item.
const updateItemColumnWhenMadeRoot = (analytics: AppAnalytics) => {
    let maxColumn = 0;
    // Find max column index.
    analytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (!i.isRoot && i.column > maxColumn) {
                maxColumn = i.column;
            }
        })
    })
    // Assigns max column index to all root items.
    analytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (i.isRoot) {
                i.column = maxColumn + 1;
            }
        })
    })
    analytics.rootColumnNumber = maxColumn + 1;
}

// Update row index of item when root item made not root item.
const updateItemColumnWhenMadeNotRoot = (analytics: AppAnalytics, item: AppAnalyticsItem) => {
    let parent;
    let hasBeenRoot = false;
    analytics.rows.forEach(r => {
        r.items.forEach(i => {
            if (item.parentId && i.id === item.parentId) parent = i;
            if (i.id === item.id && i.isRoot) hasBeenRoot = true;
        })
    })
    if (hasBeenRoot && parent) {
        // @ts-ignore
        item.column = parent.column + 1;
    }
}

// Returns new row index.
const getNewRowIndex = (analytics: AppAnalytics, item: AppAnalyticsItem) => {
    const lastItemsChildrenRows: number[] = getLastItemsChildrenRows(analytics, item.row, item.column);
    if (lastItemsChildrenRows.length > 0) {
        return getMin(lastItemsChildrenRows);
    } else {
        return getMax(analytics.rows.map(r => r.index)) + 1;
    }
}

// Returns all children rows indexes from last items.
const getLastItemsChildrenRows = (analytics: AppAnalytics, rowIndex: number, columnIndex: number): number[] => {
    const lastItemsChildrenRows: number[] = []
    analytics.rows[rowIndex].items.forEach(item => {
        if (item.column < columnIndex && item.childrenRows.length > 0) {
            lastItemsChildrenRows.push(...item.childrenRows)
        }
        if (analytics.rows[rowIndex].items.indexOf(item) === 0 && rowIndex > 0) {
            // @ts-ignore
            const parent = getParent(analytics, item.parentId);
            // @ts-ignore
            lastItemsChildrenRows.push(...getLastItemsChildrenRows(analytics, parent.row, item.column).filter(cr => cr !== rowIndex));
        }
    })
    return lastItemsChildrenRows.filter(r => r > rowIndex);
}

// Returns all children rows indexes from next items.
const getNextItemsChildrenRows = (analytics: AppAnalytics, rowIndex: number, columnIndex: number): number[] => {
    const nextItemsChildrenRows: number[] = []
    if (analytics.rows[rowIndex]) {
        analytics.rows[rowIndex].items.forEach(item => {
            if (item.column >= columnIndex && item.childrenRows.length > 0) {
                nextItemsChildrenRows.push(...item.childrenRows)
                item.childrenRows.forEach(cr => {
                    nextItemsChildrenRows.push(...getNextItemsChildrenRows(analytics, cr, item.column + 1));
                })
            }
        })
    }
    return nextItemsChildrenRows;
}

// Returns array of children items for parent item.
const getChildren = (analytics: AppAnalytics, parent: AppAnalyticsItem) => {
    const result: AppAnalyticsItem[] = [];
    analytics.rows.forEach(r => {
        if (r.index >= parent.row) {
            r.items.forEach(it => {
                if (it.parentId && it.parentId === parent.id) {
                    result.push(it);
                }
            })
        }
    })
    return result;
}

const getDeepChildren = (analytics: AppAnalytics, item: AppAnalyticsItem): AppElement[] => {
    const result: AppElement[] = [];
    const children: AppAnalyticsItem[] = getChildren(analytics, item);
    const actions: AppAnalyticsAction[] = getActions(analytics, item);

    children.forEach(c => result.push(c));
    actions.forEach(a => result.push(a));

    children.forEach(c => {
        getDeepChildren(analytics, c).forEach(dc => {
            result.push(dc)
        })
    })

    return result;
}

// Returns max numeric value from array.
const getMax = (array: number[]): number => {
    let result = 0;
    array.forEach(num => {
        if (num > result) {
            result = num;
        }
    })
    return result;
}

// Returns min numeric value from array.
const getMin = (array: number[]): number => {
    let result = Number.MAX_VALUE;
    array.forEach(num => {
        if (num < result) {
            result = num;
        }
    })
    return result;
}