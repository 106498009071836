import {Workbook, Worksheet} from "exceljs";
import {AppAnalytics, AppAnalyticsAction, AppAnalyticsRow} from "../model/types/analytics";

type coloredCell = {
    row: number;
    column: number;
    color: string
}

// Creates an xls document and starts downloading it.
export const exportAnalyticsToExcel = async (analytics: AppAnalytics) => {
    let wb: Workbook = new Workbook();
    const analyticsSheet = wb.addWorksheet('Анализ');
    const actionsSheet = wb.addWorksheet('Действия');
    fillAnalyticsSheet(analyticsSheet, analytics.rows);
    fillActionsSheet(actionsSheet, analytics.rows);

    const bytes = await wb.xlsx.writeBuffer()
    const data = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

    const a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = `Анализ от ${getCurrentDateString()}.xlsx`;
    a.click();
}

// Fills analytics sheet in the xls document.
const fillAnalyticsSheet = (sheet: Worksheet, rows: AppAnalyticsRow[]) => {
    const rootReasonColumnNumber = getRootReasonColumnNumber(rows);

    const columns = [];
    for (let i = 1; i <= rootReasonColumnNumber.maxColumn; i++) {
        if (i === 1) {
            columns.push({header: 'Главный вопрос', width: 30})
        } else if (i < rootReasonColumnNumber.maxColumn) {
            columns.push({header: `Вопрос №${i}`, width: 30})
        } else {
            if (rootReasonColumnNumber.isRoot) {
                columns.push({header: 'Корневая причина', width: 30})
            } else {
                columns.push({header: `Вопрос №${i}`, width: 30})
            }
        }
    }
    sheet.columns = columns;

    const preparedRows = prepareRowsToAnalyticsExport(rows);
    const coloredCells: coloredCell[] = [];
    preparedRows.forEach(r => {
        let rowValue: string[] = []
        r.items.forEach(item => {
            rowValue[item.column + 1] = item.text;
            if (!item.isActive) {
                coloredCells.push({row: r.index + 2, column: item.column + 1, color: 'ffb1b2'})
            } else if (item.isRoot) {
                coloredCells.push({row: r.index + 2, column: item.column + 1, color: 'b2ffb1'})
            }
        })
        sheet.addRow(rowValue)
    })

    sheet.getRow(1).font = {bold: true};
    sheet.eachRow(row => {
        row.number === 1 ? row.height = 20 : row.height = 60;
        row.eachCell((cell, colNumber) => {
            cell.alignment = {vertical: 'middle', horizontal: (row.number === 1 ? 'center' : 'left'), wrapText: true};
            if (row.number === 2 && colNumber === 1) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'lightGray',
                    bgColor: {argb: 'b1b4ff'}
                };
            }
            coloredCells
                .filter(cc => cc.row === row.number)
                .forEach(cc => {
                    if (cc.column === colNumber) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'lightGray',
                            bgColor: {argb: cc.color}
                        };
                    }
                })
        });
    });
}

// Deletes row with empty items array and changes next rows indexes.
const prepareRowsToAnalyticsExport = (rows: AppAnalyticsRow[]) => {
    const rowsToDelete: number[] = [];
    rows.forEach(r => {
        if (r.items.length === 0) {
            rowsToDelete.push(r.index);
        }
    })

    const mutableRows: AppAnalyticsRow[] = structuredClone(rows);
    rowsToDelete.forEach(rtd => {
        mutableRows.splice(rtd, 1);
        mutableRows.forEach(mr => {
            if (mr.index > rtd) {
                mr.index -= 1;
                mr.items.forEach(i => {
                    i.row -= 1;
                })
            }
        })
    })

    return mutableRows;
}

// Returns index of root questions column.
const getRootReasonColumnNumber = (rows: AppAnalyticsRow[]): any => {
    let result = {maxColumn: 0, isRoot: false}
    rows.forEach(r => {
        r.items.forEach(i => {
            if (i.isRoot) {
                result.isRoot = true;
            }
            if (i.column + 1 > result.maxColumn) {
                result.maxColumn = i.column + 1;
            }
        })
    })
    return result;
}

// Returns current date in format: dd.MM.yyyy
const getCurrentDateString = () => {
    const currentDate = new Date();
    const date = currentDate.getDate().toString().length === 1 ? `0${currentDate.getDate().toString()}` : currentDate.getDate().toString();
    const month = (currentDate.getMonth() + 1).toString().length === 1 ? `0${(currentDate.getMonth() + 1).toString()}` : (currentDate.getMonth() + 1).toString();
    return `${date}.${month}.${currentDate.getFullYear()}`;
}

// Fills action sheet in the xls document.
const fillActionsSheet = (sheet: Worksheet, rows: AppAnalyticsRow[]) => {
    const actions: AppAnalyticsAction[] = [];
    rows.forEach(r => {
        r.actions.forEach(ac => {
            actions.push(ac);
        })
    })
    if (actions.length > 0) {
        sheet.columns = [
            {header: 'Действие', key: 'action', width: 60},
            {header: 'Ответственный', key: 'responsible', width: 30},
            {header: 'Дата', key: 'date', width: 30},
        ]

        const coloredRows: number[] = [];
        actions.forEach(action => {
            let rowValue: string[] = []
            rowValue[1] = action.text;
            rowValue[2] = action.responsible;
            rowValue[3] = action.date;

            if (!action.isActive) {
                coloredRows.push(action.row)
            }

            sheet.addRow(rowValue);
        })

        sheet.getRow(1).font = {bold: true};
        sheet.eachRow(row => {
            row.height = row.number === 1 ? 20 : 30;
            const isRowColored = coloredRows.some(r => r === row.number - 2);
            row.eachCell(cell => {
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: (row.number === 1 ? 'center' : 'left'),
                    wrapText: true
                };
                if (isRowColored) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'lightGray',
                        bgColor: {argb: 'ffb1b2'}
                    }
                }
            });
        });
    }
}