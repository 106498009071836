import React from 'react';
import st from './horizontal-scroll.clue.module.scss';
import rightArrowImage from "../../../images/icons/right-arrow.png";
import {horizontalScrollClue, imagesAlt} from "../../../util/textVariables";

const HorizontalScrollClue: React.FC<{ isActive: boolean }> = ({isActive}) => {

    return (
        <div className={isActive ? [st.main, st.active].join(' ') : st.main}>
            <div className={st.arrowContainer}>
                <img src={rightArrowImage} className={[st.arrow, st.left].join(' ')} alt={imagesAlt}/>
            </div>
            <div className={st.clueContainer}>
                <span className={st.clue}>{horizontalScrollClue}</span>
            </div>
            <div className={st.arrowContainer}>
                <img src={rightArrowImage} className={st.arrow} alt={imagesAlt}/>
            </div>
        </div>

    );
};

export default HorizontalScrollClue;