import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import st from './action-bar.module.scss';
import triangleArrowIcon from '../../../images/icons/triangle-arrow-right.png';
import {
    blockButtonTitle, deleteButtonTitle,
    imagesAlt, makeAction,
    rootButtonTitle, unblockButtonTitle, unRootButtonTitle,
    whatButtonTitle
} from "../../../util/textVariables";
import ActionButton from "../action-button/action-button";
import {ActionButtonType} from "../../../model/enums/action-button-type";
import {AppAnalyticsItem} from "../../../model/types/analytics";
import {useAppSelector} from "../../../hooks/reduxHooks";
import {isLastInRow} from "../../../util/action-functions";

const ActionBar:React.FC<{item:AppAnalyticsItem, handler:Function}> = ({item, handler}) => {
    const analytics = useAppSelector(state => state.analytics);

    const openButtonRef = useRef<HTMLImageElement>(null);
    const actionButtonsRef = useRef<HTMLDivElement>(null);

    const [isMouseOnOpenButton, setIsMouseOnOpenButton] = useState(false);
    const [isMouseOnActionButtons, setIsMouseOnActionButtons] = useState(false);
    const [actionButtonsVisible, setActionButtonsVisible] = useState(false);

    // Listens open and actions buttons mouseover and sets states.
    useEffect(() => {
        (openButtonRef as MutableRefObject<HTMLImageElement>).current.addEventListener('mouseover', () => {
            setIsMouseOnOpenButton(true);
        });

        (openButtonRef as MutableRefObject<HTMLImageElement>).current.addEventListener('mouseleave', () => {
            const timeout = setTimeout(() => setIsMouseOnOpenButton(false), 100);
            return () => clearTimeout(timeout);
        });

        (actionButtonsRef as MutableRefObject<HTMLDivElement>).current.addEventListener('mouseover', () => {
            setIsMouseOnActionButtons(true);
        });

        (actionButtonsRef as MutableRefObject<HTMLDivElement>).current.addEventListener('mouseleave', () => {
            setIsMouseOnActionButtons(false);
        });
    }, [])

    // Sets action buttons visible state.
    useEffect(() => {
        setActionButtonsVisible(isMouseOnOpenButton || isMouseOnActionButtons);
    }, [isMouseOnOpenButton, isMouseOnActionButtons])

    // Shows and hides action buttons container.
    useEffect(() => {
        if (actionButtonsVisible) {
            (actionButtonsRef as MutableRefObject<HTMLDivElement>).current.className = st.actionButtonsContainer;
        } else {
            const timeout = setTimeout(() => {
                (actionButtonsRef as MutableRefObject<HTMLDivElement>).current.className = [st.actionButtonsContainer, st.notVisible].join(' ');
            }, 500)
            return () => clearTimeout(timeout);
        }
    }, [actionButtonsVisible])

    const getActionButtons = () => {
        if (item.row === 0 && item.column === 0) {
            return <ActionButton type={ActionButtonType.question} orderNumber={2} active={actionButtonsVisible} title={whatButtonTitle} onClick={handler}/>
        } else if (item.isActive && !item.isRoot) {
            return <>
                <ActionButton type={ActionButtonType.question} orderNumber={2} active={actionButtonsVisible} title={whatButtonTitle} onClick={handler}/>
                { isLastInRow(analytics, item) &&
                    <ActionButton type={ActionButtonType.ok} orderNumber={3} active={actionButtonsVisible} title={rootButtonTitle} onClick={handler}/>
                }
                <ActionButton type={ActionButtonType.block} orderNumber={4} active={actionButtonsVisible} title={blockButtonTitle} onClick={handler}/>
                <ActionButton type={ActionButtonType.delete} orderNumber={5} active={actionButtonsVisible} title={deleteButtonTitle} onClick={handler}/>
            </>
        } else if (!item.isActive) {
            return <>
                <ActionButton type={ActionButtonType.ok} orderNumber={1} active={actionButtonsVisible} title={unblockButtonTitle} onClick={handler}/>
                <ActionButton type={ActionButtonType.delete} orderNumber={3} active={actionButtonsVisible} title={deleteButtonTitle} onClick={handler}/>
            </>
        } else {
            return <>
                <ActionButton type={ActionButtonType.plus} orderNumber={1} active={actionButtonsVisible} title={makeAction} onClick={handler}/>
                <ActionButton type={ActionButtonType.block} orderNumber={3} active={actionButtonsVisible} title={unRootButtonTitle} onClick={handler}/>
                <ActionButton type={ActionButtonType.delete} orderNumber={5} active={actionButtonsVisible} title={deleteButtonTitle} onClick={handler}/>
            </>
        }
    }

    return (
        <div className={st.main}>
            <img src={triangleArrowIcon} className={st.img} alt={imagesAlt} ref={openButtonRef}/>
            <div
                className={[st.actionButtonsContainer, st.notVisible].join(' ')}
                ref={actionButtonsRef}>
                {getActionButtons()}
            </div>
        </div>
    );
};

export default ActionBar;