import React from 'react';
import st from './arrows.module.scss';

const RightArrow = () => {
    return (
        <div className={st.main}>
            <div className={st.rightArrow}>
                <div className={st.arrowContainer}>
                    <div className={st.arrow}/>
                </div>
            </div>
        </div>
    );
};

export default RightArrow;