import React, {MutableRefObject, useEffect, useRef} from 'react';
import './App.scss';
import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/main-page/main-page";
import AnalyticsPage from "./pages/analytics-page/analytics-page";
import {analyticsRoute, homeRoute} from "./util/routes";
import {useAppDispatch, useAppSelector} from "./hooks/reduxHooks";
import {addToHistory} from "./store/slices/analyticsHistorySlice";
import {updateAnalyticsFromHistory} from "./store/slices/analyticsSlice";

function App() {
    const appRef = useRef<HTMLDivElement>(null);
    const analytics = useAppSelector(state => state.analytics);

    const dispatch = useAppDispatch();

    // Blocks browser scaling for an app.
    useEffect(() => {
        (appRef as MutableRefObject<HTMLDivElement>).current.addEventListener('wheel', (e) => {
            if (e.ctrlKey) {
                e.preventDefault()
            }
        });
    }, [])


    useEffect(() => {
        if (analytics.isNew) {
            dispatch(addToHistory(analytics))
        }
    }, [analytics]);

    const {history, currentIndex} = useAppSelector(state => state.history);
    useEffect(() => {
        const analyticsFromHistory = history[currentIndex];
        if (analyticsFromHistory) {
            dispatch(updateAnalyticsFromHistory(analyticsFromHistory));
        }
    }, [currentIndex])

    return (
        <div className="App" ref={appRef}>
            <Routes>
                <Route path={homeRoute} element={<MainPage/>}/>
                <Route path={analyticsRoute} element={<AnalyticsPage/>}/>
            </Routes>
        </div>
    );
}

export default App;
