import { configureStore } from '@reduxjs/toolkit';
import Analytics from './slices/analyticsSlice'
import AnalyticsDesk from './slices/analyticsDeskSlice'
import ModalState from "./slices/modalSlice";
import AnalyticsHistorySlice from "./slices/analyticsHistorySlice";

const store = configureStore({
  reducer: {
    analytics: Analytics,
    history: AnalyticsHistorySlice,
    analyticsDesk: AnalyticsDesk,
    modal: ModalState
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
