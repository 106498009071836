import React from 'react';
import st from './modal.module.scss';
import closeIcon from '../../../images/icons/plus.png';
import {imagesAlt} from "../../../util/textVariables";

const Modal: React.FC<{ active: boolean, setActive: Function, children: any }> = ({active, setActive, children}) => {

    const closeModal = () => {
        setActive(false)
    }

    return (
        <div className={active ? [st.background, st.active].join(' ') : st.background} onClick={closeModal}>
            <div className={active ? [st.modal, st.active].join(' ') : st.modal} onClick={e => e.stopPropagation()}>
                {children}
                <div className={st.closeButtonContainer} onClick={closeModal}>
                    <img src={closeIcon} className={st.closeButton} alt={imagesAlt}/>
                </div>
            </div>
        </div>
    );
};

export default Modal;