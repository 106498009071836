import React, {MutableRefObject, useEffect, useRef} from 'react';
import st from './analytics-general-action-button.module.scss';

const AnalyticsGeneralActionButton:React.FC<{bottom: number, right: number, children: any}> = ({bottom, right, children}) => {
    const mainRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        (mainRef as MutableRefObject<HTMLDivElement>).current.style.bottom = `${bottom}px`;
        (mainRef as MutableRefObject<HTMLDivElement>).current.style.right = `${right}px`
    }, [])

    return (
        <div className={st.main} ref={mainRef}>
            {children}
        </div>
    );
};

export default AnalyticsGeneralActionButton;

